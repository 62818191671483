import './UnderReconstruction.css'
import whiteTowers from '../../assets/whiteTowers.svg'
import Header from '../Header/Header/Header.js'
import Footer from '../../components/Footer/Footer.js'
import { UserContext } from '../../userContext'
import { useContext } from 'react'

const UnderReconstruction = () => {
    const { lang } = useContext(UserContext);
    return ( 
        <div className="under-reconstruction">
            <Header/>
            <div className="alert-text">
               {lang=="ge"?<h2>საცხოვრებელი და საოფისე ფართების გაქირავება</h2>:<h2 className='h2en_cap'>Rental of residential and office spaces</h2>}
               {lang=="ge"?<p>გვერდი მზადების პროცესშია</p>:<p className='p3en'>The page is under construction</p>}
            </div>
            <img src={whiteTowers} className="whiteTowers" alt="" />
            <Footer/>
        </div>
     );
}
 
export default UnderReconstruction;