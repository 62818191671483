import './Home.css'
import Header from '../Header/Header/Header.js'
import Footer from '../Footer/Footer.js'
import { Link } from 'react-router-dom';
import { UserContext } from '../../userContext';
import { useContext } from 'react';
import leftBackground from '../../assets/backgr.jpg'

const Home = () => {
    const { lang } = useContext(UserContext);

    return (  
       <div className={"home-component"}>
        <Header/>
         <div className="middle-container">
         <div className="left-home-component">
            <img src={leftBackground} alt="" />
        </div>
        <div className="middle">
            <div className={`company-name h1${lang}_cap`}>{lang=="ge"?"შპს რეზიდ სერვისი":"LLC Rezid Service"}</div>
            <p className={`into p2${lang}`}>{lang=="ge"?"წარმოადგენს King David Residences ის მმართველ კომპანიას. უზრუნველყოფს საცხოვრებელი და ბიზნეს კომპლექსების გამართულ და მდგრად ფუნქციონირებას":
                                "Represents the management company of King David Residences. Ensures proper and sustainable operation of residential and business complexes"}</p>
            <Link to="/about" className={`more link p1${lang}`}> {lang=="ge"?"ვრცლად ...":"more ..."}</Link>
            <Link to="/alert" className={`organizational-structure-button link renting p3${lang}_cap`}>{lang=="ge"?"საცხოვრებელი და საოფისე ფართების გაქირავება":"Rental of residential and office spaces"}</Link>
        </div>
         </div>
        <Footer/>
       </div>
    );
}
 
export default Home;