import cleaning from '../../assets/clean.svg'
import cleaning_white from '../../assets/clean_white.svg'

import fasad from '../../assets/fasad.svg'
import fasad_white from '../../assets/fasad_white.svg'

import tree from '../../assets/tree.svg'
import tree_white from '../../assets/tree_white.svg'

import pipeline from '../../assets/pipeline.svg'
import pipeline_white from '../../assets/pipeline_white.svg'

import elevator from '../../assets/elevator.svg'
import elevator_white from '../../assets/elevator_white.svg'

import buildings from '../../assets/buildings.svg'
import buildings_white from '../../assets/buildings_white.svg'

import safety from '../../assets/security.svg'
import safety_white from '../../assets/security_white.svg'

export const servicesdb={
    services:[
        {
            id:1,
            name_ge:"დალაგება-დასუფთავება",
            name_en:"cleaning",
            image:cleaning,
            image_white:cleaning_white,
            services_ge:[
                "კომპლექსის ეზოს დაგვა-დასუფთავება",
                "საერთო ფართების, ჰოლების, პარკინგში ჩასასვლელების, პარკინგის ტერიტორიის დაგვა-გაწმენდა-დასუფთავება",
                "სადარბაზოების და საზოგადოებრივ ფართში არსებული ავეჯის და მოწყობილობების დასუფთავება",
                "კომპლექსის შიგნით და პერიმეტრზე არსებული ლაითბოქსების, სარეკლამო წარწერების, ბანკომატების, გადახდის აპარატების და სხვა დანადგარების წმენდა",
                "საერთო სარგებლობაში არსებული ფართების და შიდა ინტერიერის სისუფთავეზე ზრუნვას: სველი წერტილების დასუფთავებას, შუშის ფანჯრის და კარის კვარტალის დასუფთავება, ხალიჩის ყოველდღიური მტვერსასრუტით გაწმენდა, 6 თვეში ერთხელ სიღრმისეული გაწმენდა",
                "პარკინგის და მისასვლელი გზის დასუფავება თვეში ერჯელ ან საჭიროებისამებრ",
                "გარე და შიდა პარკინგის, ბილიკების და გზების თოვლისგან გაწმენდა."],
            services_en:[
                "Cleaning of the yard of the complex",
                "Cleaning of common areas, halls, entrances to the parking lot, parking area",
                "Cleaning of entrances and furniture and equipment in public space",
                "Cleaning of lightboxes, advertising signs, ATMs, payment machines and other devices inside the complex and on the perimeter",
                "Taking care of the cleanliness of common areas and interior: cleaning of bathrooms, cleaning of glass window and door quarter, daily vacuuming of carpets, deep cleaning once in 6 months",
                "Parking lot and driveway cleaning monthly or as needed",
                "Cleaning of outdoor and indoor parking, paths and roads from snow."
            ],
        },
        {
            id:2,
            name_ge:"კომპლექსის ფასადი",
            name_en:"facade",
            image:fasad,
            image_white:fasad_white,
            services_ge:[
                "არსებული იერსახის შენარჩუნება",
                "ფასადის ვიტრაჟების გეგმიური წმენდა არანაკლებ წელიწადში ორჯელ",
                "ფასადის ვიტრაჟების არაგეგმიურ წმენდა განსაკუთრებული ან/და ექსტრემალური ბუნებრივი კლიმატური პირობებიდან გამომდინარე (რეზიდ ჰოლდინგთან შეთანხმებით)",
                "საცხოვრებელი და საოფისე კოშკის სახურავის ყინულისგან გაწმენდა."
            ],
            services_en:[
                "Maintaining the existing appearance",
                "Scheduled cleaning of facade stained glass at least twice a year",
                "Unscheduled cleaning of facade stained glass due to special and/or extreme natural climatic conditions (in agreement with Resid Holding)",
                "De-icing of residential and office tower roofs."
            ],
        },
        {
            id:3,
            name_ge:"გამწვანება - ლანდშაფტი",
            name_en:"landscape",
            image:tree,
            image_white:tree_white,
            services_ge:[
                "კომპლექსის წითელ ხაზებში არსებული ტერიტორიის კეთილმოწყობა-გამწვანება",
                "არსებული ლანდშაფტის სათანადო მდგომარეობის შენარჩუნება",
                "საჭიროებისამებრ ფოთლების შეგროვება",
                "საჭიროებისამებრ გაზონის ბალახის თიბვა, მორწვა, ბალახის საფარის დაცვა",
                "დაზიანებული მცენარის ახლით ჩანაცვლება."
            ],
            services_en:[
                "Landscaping of the area in the red lines of the complex",
                "Maintaining the proper state of the existing landscape",
                "Collection of leaves as needed",
                "Lawn mowing, watering, maintenance of grass cover as needed",
                "Replacing the damaged plant with a new one."
            ],
        },
        {
            id:4,
            name_ge:"საინჟინრო კომუნიკაცია",
            name_en:"Engineering communication",
            image:pipeline,
            image_white:pipeline_white,
            services_ge:[
                "კორპუსის წითელ ხაზებში არსებული ტერიტორიის, ლობების, პარკინგის, მისასვლელების, ტერასების და სხვა საერთო სარგებლობის ფართების განათება",
                "საერთო სარგებლობის ფართების ვენტილაციას და გათბობა-გაგრილება",
                "ლობების უსადენო ინტერნეტ კავშირით უზრუნველყოფა",
                "საცხოვრებელი და საოფისე ფართებისთვის ელექტროენერგიის და წყლის (ცხელი და ცივი) მიწოდება, ვენტილაციით და გათბობა-გაგრილებით უზრუნველყოფა.",
                
            ],
            services_en:[
                "Lighting of the territory, lobbies, parking, entrances, terraces and other common areas in the red lines of the building ",
                "Ventilation and heating-cooling of common areas",
                "Provision of lobbies with wireless Internet connection",
                "Provision of electricity and water (hot and cold), ventilation and heating-cooling for residential and office spaces."
            ],
        },
        {
            id:5,
            name_ge:"ტექნიკური ინფრასტრუქტურა",
            name_en:"technical infrastructure",
            image:elevator,
            image_white:elevator_white,
            services_ge:[
                "ლიფტებისა და ექსკალატორების გამარულობას, საბაზო მომსახურებას პროფილაქტიკური შემოწმება",
                "საცხოვრებელ და საოფისე კოშკებში, ასევე ავტოსადგომებზე სახანძარსაწინააღმეგო (ტუმბოების, ცეცხლმაქრების, კვამლის დეტექტორების და ჰიდრანების) და განგაშის სისტემების გამართულობის უზრუნველყოფა და კონტროლი",
                "უწყვეტი ელექტრო ენერგიით უზრუნველსაყოფად საჭირო დანადგარების (ტრანსფორმატორი, გამანაწილებელი ფარები, გენერატორი, კაბელის ტრასები) გამართულობა",
                "ვენტილაციის და გათბობა-გაგრილების სისტემების გამართულობას",
                
            ],
            services_en:[
                "Preventive inspection of elevators and escalators, basic services",
                "Ensuring and controlling the functioning of fire protection (pumps, fire extinguishers, smoke detectors and hydrants) and alarm systems in residential and office towers, as well as in parking lots",
                "Serviceability of equipment required to provide continuous electric power (transformer, distribution shields, generator, cable tracks)",
                "the functioning of ventilation and heating-cooling systems",
                
            ],
        },
        {
            id:6,
            name_ge:"კომპლექსის ექსპლუატაცია",
            name_en:"Operation of the complex",
            image:buildings,
            image_white:buildings_white,
            services_ge:[
               "საერთო სარგებლობის ფართებში სახელურების, საკეტების და ნათურების საჭიროებისამებრ გამოცვლა",
               "ნარჩენების გატანის ადგილის გამოყოფა, მათი გატანის დროისა და მარშრუტების ორგანიზება",
               "საცხოვრებელ და საოფისე კოშკებში მიმდინარე სარემონტო სამუშაოების კოორდინაცია"
                
            ],
            services_en:[
                "Replacement of handles, locks and light bulbs in common areas as needed",
                "Allocating a place for waste removal, organizing the time and routes of their removal",
                "Coordination of ongoing renovation works in residential and office towers"
            ],
        },
        {
            id:7,
            name_ge:"უსაფრთხპება",
            name_en:"safety",
            image:safety,
            image_white:safety_white,
            services_ge:[
               "კომპლექსში უნებლიე შეღწევადობის შეზღუდვა",
               "უცხო პირების (ვიზიტორების) დაშვება და გადაადგილების მონიტორინგი",
               "ავტომობილების პარკირებისა და გადაადგილების ორგანიზება და მონიტორინგი",
               "რეზიდ სერვისისა და რეზიდ ჰოლდინგის მატერიალური საშუალებების დაცვა",
               "ვიდეო სათვალთვალო კამერების გამართული მუშაობა და 24 საათიანი მონიტორინგი",
               "24 საათიანი საგანგებო სატელეფონო ცხელი ხაზის გამართულობა"
                
            ],
            services_en:[
                "Limitation of inadvertent access to the complex",
                "Admission of foreigners (visitors) and monitoring of movement",
                "Organization and monitoring of car parking and movement",
                "Protection of Resid Service and Resid Holding's material assets",
                "Proper operation of video surveillance cameras and 24-hour monitoring",
                "24-hour emergency telephone hotline"
            ],
        }
    ]
}















