import './Contact.css'
import Header from '../Header/Header/Header';
import Footer from '../Footer/Footer';
import blackTowers from '../../assets/blackTowers.svg'
import { UserContext } from '../../userContext';
import { useContext } from 'react';

const Contact = () => {
    const { lang } = useContext(UserContext);
    
    return ( 
        <div className="contact-container">
        <Header/>
           <div className="contact-inner-container">
                <div className="contact-left">
                     <img src={blackTowers} alt="" />
                     <div className="companyInformation">
                        <div className="infos">
                            {lang=="ge"?<p>მისამართი:</p>:<p className='p3en'>Address:</p>}
                            {lang=="ge"?<p>საქართველო, თბილისი, მერაბ ალექსიძის ქუჩა #12</p>:<p className='p2en'>Georgia, Tbilisi, Merab Aleksidze Street #12</p>}
                        </div>
                        <div className="infos">
                            {lang=="ge"?<p>ტელეფონი:</p>:<p className='p3en'>Telephone:</p>}
                            <p>032 200 18 18 </p>
                        </div>
                        <div className="infos">
                            {lang=="ge"?<p>ელფოსტა:</p>:<p className='p3en'>Email:</p>}
                            <p>info@kdr.ge </p>
                        </div>
                        <div className="infos">
                            {lang=="ge"?<p>ვებ გვერდი:</p>:<p className='p3en'>Website:</p>}
                            <p>kdr.ge</p>
                        </div>
                     </div>
                </div>
                <div className="contact-right">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2978.1749754677007!2d44.78318951558186!3d41.71674298347495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x404472d52f61ca71%3A0xae9dcb0cda349f9f!2sKing%20David%20Residences!5e0!3m2!1sen!2sge!4v1679948047881!5m2!1sen!2sge" 
                        width="100%" 
                        height="100%" 
                        style={{border : "0"}}
                        allowfullscreen="" 
                        loading="lazy" 
                        referrerpolicy="no-referrer-when-downgrade">                            
                </iframe>
                </div>
           </div>
         <Footer/>
        </div>
     );
}
 
export default Contact;

