import './Header.css'
import { Link } from 'react-router-dom';
import { UserContext } from '../../../userContext';
import { useContext } from 'react';
import burgermenu from '../../../assets/whiteBurger.svg'
import { useState } from 'react';

const Header = () => {
    var currentUrl=window.location.href.split('/')
    var current=currentUrl[currentUrl.length-1]

    var [showMenu, setShowMenu]= useState("flex");

    const toggleMenu=  () =>{
           if (showMenu == "flex"){
            setShowMenu("none");
           
            
           } else{
            setShowMenu("flex")
            
           }
    }
    
    const {
        changeLang,
        lang
      } = useContext(UserContext);

     const switchLanguage=()=>{
        console.log(lang)
        if(lang == "ge"){
            changeLang("en")
        }else{
            changeLang("ge")
        }
     } 

    return ( <div className="header-container">
       <Link to='/' className='link'>
       <div className="header-left">
         {lang=="ge"? "შპს რეზიდ სერვისი":"LLC REZID SERVICE"}
       </div>
       </Link>

       
            <ul style={{display:showMenu}}>
                <li>
                <Link to='/about' className='link'> 
                <div className={`link-container p3${lang}_cap`}>
                    {lang=="ge"?"ჩვენს შესახებ":"about us"}
                <div className={current=='about'?"underline":"noUnderline"}></div>
            </div>
                </Link>
                </li>

                <li>
                <Link to='/services' className='link'> 
                <div className={`link-container p3${lang}_cap`}>
                  {lang=="ge"?"ფუნქციები":"functions"}
                <div className={current=='services'?"underline":"noUnderline"}></div>
            </div>
                </Link>
                </li>
                
                <li>
                <Link to='/contact' className='link'> 
                <div className={`link-container p3${lang}_cap`}>
                  {lang=="ge"?"კონტაქტი":"contact"}
                <div className={current=='contact'?"underline":"noUnderline"}></div>
            </div>
                </Link>
                </li>
               
                <li><div onClick={switchLanguage} className="switch-language">{lang=="ge"? "GEO":"ENG"}</div></li>
            </ul>
            <img className="burgermenu" src={burgermenu} onClick={toggleMenu} />
            
       </div>
   )
}
 
export default Header;