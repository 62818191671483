import './Services.css'
import Header from '../Header/Header/Header';
import Footer from '../Footer/Footer';
import img from '../../assets/clean.svg'
import {servicesdb} from './servicesdb';
import { useState, useContext } from 'react';
import whiteTowers from '../../assets/whiteTowers.svg'
import { UserContext } from '../../userContext';

const Serivces = () => {
    const { lang } = useContext(UserContext);
    var [currentService, setCurrentService]=useState(servicesdb.services[0]);
   
    return ( 
        <div className="service-container">
        <Header/>
            <div className="services-container">
            {servicesdb.services.map(a =><div className='services-list' onClick={()=>{setCurrentService(a)}}>
               <div className={currentService.id==a.id?"current-service img-container":"img-container"}>
                  <img src={currentService.id==a.id?a.image_white:a.image} />
               </div>
               {lang=="ge"?<p>{a.name_ge}</p>:<p className="p11en_cap">{a.name_en}</p>}
               
            </div>)
            }
            </div>

            <div className="right-service-container">
            <div className="current-service-container">
                {(lang=="ge")&& 
                <ul className='services_list_container'>
                    {currentService.services_ge.map(a=><div>
              
                        <li >{a}</li>
                    </div>)}
                </ul>
               }

                {( lang=="en")&&<ul className='services_list_container'>
                    {currentService.services_en.map(a=><div>
                        <li className="p22en">{a}</li>
                    </div>)}
                </ul>}

            </div>
            <img src={whiteTowers} className="whiteTowers2" alt="" />
            </div>
        <Footer/>    
        
        </div>
     );
}
 
export default Serivces;