import Home from './components/HomePage/Home.js'
import About from './components/AboutPage/About.js'
import Contact from './components/ContactPage/Contact.js'
import Services from './components/servicesPage/Services.js'
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useState, useEffect} from 'react';
import { UserContext } from './userContext.js'
import UnderReconstruction from './components/UnderReconstruction/UnderReconstruction.js'

function App() {

  const [lang, set_lang] = useState(
    localStorage.getItem("lang") !== undefined
      ? localStorage.getItem("lang")
      : "ge"
  );
  const changeLang = (newLang) => {
    set_lang(newLang);
    localStorage.setItem("lang", newLang);
  };
  useEffect(() => {
    if (!localStorage.getItem("lang")) {
      localStorage.setItem("lang", "ge");
    }
  }, []);

  return (
    <div className="App">
     
      <Router>
      <UserContext.Provider
        value={{        
          changeLang,
          lang
        }}
      >
        <Routes>
          <Route>
             <Route path="/" element={<Home/>}/>
             <Route path="/about" element={<About/>}/>
             <Route path="/services" element={<Services/>}/>
             <Route path="/contact" element={<Contact/>}/>
             <Route path="/alert" element={<UnderReconstruction/>}/>
          </Route>
        </Routes>
        </UserContext.Provider>
      </Router>
    </div>
  );
}

export default App;
