import './About.css'
import Header from '../Header/Header/Header';
import Footer from '../Footer/Footer';
import pdf from '../../assets/pdf.svg';
import whiteTowers from '../../assets/whiteTowers.svg'
import { Link } from 'react-router-dom';
// import report_ge from '../../assets/report_ge.pdf'
// import report_en from '../../assets/report_en.pdf'
import { UserContext } from '../../userContext';
import { useContext } from 'react';

const About = () => {
    const { lang } = useContext(UserContext);

    return ( 
        <div className="about-container">
        <Header/>
         <div className="about-left">
            {lang=="ge"?<h1>შპს რეზიდ სერვისი</h1>:<h1 className='h2en_cap'>LLC Rezid Service</h1> }
            {lang=="ge"?
            <p className='aboutInfo_ge'>
            შპს რეზიდ სერვისი „რეზიდ ჰოლდინგ ჯიემბიეიჩ-ის (REZID HOLDING GMBH) ფილიალი საქართველოში რეზიდ-თბილისი“-ს შვილობილი კომპანიაა, რომელიც დაფუძნდა 2017 წლის 6 ივლისს. შპს „რეზიდ სერვისი“ წარმოადგენს მრავალფუნქციური კომპლექსი King David-ის მმართველ კომპანიას. <br/><br/>
            კომპლექსი შედგება 2 კოშკისგან. მაღალი კოშკი, რომელიც არის 36 სართულიანი და წარმოადგენს საცხოვრებელ კომპლექსს, ხოლო შედარებით დაბალი, 23 სართულიანი კორპუსი, წარმოადგენს ბიზნეს ცენტრს. კომპლექსი ცალკეულად დაყოფილია საცხოვრებელ, საოფისე, კომერციულ, საპარკინგე და საზოგადოებრივ ფართებად, რომელთა სრული ფართობი ჯამში შეადგენს 80 000-მდე კვადრატულ მეტრს. <br/><br/>
            შპს „რეზიდ სერვისი“ სწრაფად მზარდი კომპანიაა, რომელმაც 2017 წლიდან დღემდე მოახერხა მომხმარებლებს შესთავაზოს მრავალფეროვანი სერვისები, კონკრეტულ მოთხოვნებზე მორგებული კომპლექსური მიდგომით. ბოლო მონაცემებით კომპანიაში დასაქმებულია 80 ადამიანი, რომლებიც გაერთიანებული არიან კომპანიის ორგანიზაციულ სტრუქტურაში.<br/>
            </p>:
            <p className='aboutInfo_en'>
            Ltd. Rezid Service is a subsidiary company of Rezid-Tbilisi, a branch of Rezid Holding GMBH in Georgia, which was founded on July 6, 2017. "Resid Service" Ltd. is the managing company of the multifunctional complex King David. <br/><br/>
              The complex consists of 2 towers. The tallest tower, which is 36 stories high, is a residential complex, while the lower, 23-story building is a business center. The complex is divided into residential, office, commercial, parking and public spaces, the total area covers about 80,000 square meters. <br/><br/>
              "Resid Service" Ltd. is a rapidly growing company, which in 2017 According to the latest data, the company employs 80 people who are united in the structure of organizations.<br/>
            </p>}
            <a href={lang=="ge"?"ამონაწერი-შპს რეზიდ სერვისი.pdf":"extract from registry - Rezid Service.pdf"} target="_blank" className='blackLink link'>
            <div className="pdf-container" >
                <img src={pdf} alt="" />
             {lang=="ge"?<p className='pdf_ge'>ამონაწერი საჯარო რეესტრიდან</p>:<p className='p3en pdf_en'>Extract from Registry</p>}
            </div>
            </a>
         </div>

         <div className="about-right" >
          <Link to="/alert" className=' link'>{lang=="ge"?<p className='organizational-structure-button2'>ორგანიზაციული სტრუქტურა</p>:<p className='p22en_cap organizational-structure-button2'>organizational structure</p>} </Link>
            <img className='whiteTowers' src={whiteTowers} alt="" />
         </div>
         <Footer/>
        </div>
     );
}
 
export default About;